<div class="forgot-password-root">
  <div class="form-wrapper">
    <div class="form-column">
      <mat-icon [svgIcon]="svgIconsEnum.HeaderLogo" class="logo-full"></mat-icon>

      <h2 class="fs-24 fw-600 text-black lh-32 mb-8">
        {{ cardTitle | translate}}
      </h2>

      <div *ngIf="state == ForgotPasswordStates.EMAIL_INPUT">
        <div class="fs-14 text-gray-600 fw-500 lh-20 mb-32">
          {{ 'FORGOT_COMPONENT.INIT_INFO_TEXT' | translate }}
        </div>
        <tc-input [control]="restorePasswordEmailForm.username">
          <ng-container label>Email</ng-container>
        </tc-input>

        <tc-button
          (onClick)="reset()"
          [disabled]="loading"
          type="primary"
          size="x-large"
          class="full-width mt-32"
        >
          {{ 'FORGOT_COMPONENT.RESET_PASSWORD' | translate }}
        </tc-button>
      </div>

      <div
        *ngIf="state == ForgotPasswordStates.EMAIL_SENT"
      >
        <h2 class="fs-14 fw-700 lh-20 text-black mb-8">
          {{ 'FORGOT_COMPONENT.EMAIL_SENT' | translate }}
        </h2>
        <p class="text-gray-600 fs-14 fw-500 lh-20">
          {{ 'FORGOT_COMPONENT.LINK_SEND' | translate }}
          <span class="email">{{restorePasswordEmailForm.username.value}}</span>
        </p>
      </div>

      <div *ngIf="state == ForgotPasswordStates.PASSWORD_INPUT && hasToken">
        <div class="mt-24">
          <div style="position: relative">
            <tc-input [control]="changePasswordForm.changePasswordNew"
                      [type]="showPass ? 'text' : 'password'"
                      (onFocus)="showTooltip = true"
                      (onBlur)="showTooltip = false">
              <ng-container label>{{ 'FORGOT_COMPONENT.NEW_PASSWORD' | translate }}</ng-container>
              <ng-container icon>
                <mat-icon class="input-icon"
                          (click)="showPass = !showPass"
                          [svgIcon]="showPass ? svgIconsEnum.EyeOpen : svgIconsEnum.EyeClose"></mat-icon>
              </ng-container>
            </tc-input>

            <tc-password-strength-tooltip [showTooltip]="showTooltip"
                                          [passwordComponent]="passwordComponent">
            </tc-password-strength-tooltip>
          </div>
          <div class="strength-bar"
               [class.visible]="changePasswordForm.changePasswordNew?.touched || changePasswordForm.changePasswordNew?.dirty">
            <password-strength [password]="changePasswordForm.changePasswordNew.value"
                               #passwordComponent
                               [min]="minPasswordLength"
                               class="mb-16"
            ></password-strength>
          </div>
        </div>

        <tc-input [control]="changePasswordForm.changePasswordConfirm"
                  [type]="showPassConfirm ? 'text' : 'password'"
                  type="password">
          <ng-container label>{{ 'REGISTER_COMPONENT.CONFIRM' | translate }}</ng-container>
          <ng-container icon>
            <mat-icon class="input-icon"
                      (click)="showPassConfirm = !showPassConfirm"
                      [svgIcon]="showPassConfirm ? svgIconsEnum.EyeOpen : svgIconsEnum.EyeClose"></mat-icon>
          </ng-container>
        </tc-input>

        <tc-button type="primary"
                   size="x-large"
                   class="full-width mt-40"
                   (onClick)="change()"
                   [disabled]="isChangePasswordButtonDisabled"
        >
          {{ 'FORGOT_COMPONENT.CHANGE_PASSWORD' | translate }}
        </tc-button>
      </div>

      <div *ngIf="state == ForgotPasswordStates.PASSWORD_CHANGED">
        <h2 class="fs-14 fw-600 lh-20 text-black mb-8">{{ 'FORGOT_COMPONENT.CHANGED_SUCCESS' | translate }}</h2>
        <p class="text-gray-600 fs-14 lh-20 fw-500 mb-32">{{ 'FORGOT_COMPONENT.YOU_CAN_LOGIN' | translate }}</p>
        <tc-button class="full-width"
                   type="primary"
                   size="x-large"
                   [routerLink]="['/login']"
        >
          {{ 'FORGOT_COMPONENT.LOGIN' | translate }}
        </tc-button>
      </div>
    </div>
  </div>

  <app-auth-pages-footer></app-auth-pages-footer>
</div>

<tc-email-not-found-dialog></tc-email-not-found-dialog>
